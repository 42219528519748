import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import { DEALS, STARTING_QUESTION } from 'constants/index';

import Typography from 'components/common/typography';
import Modal from 'components/common/modal';
import Button from 'components/common/button';

import useRoutesService from 'services/routes';
import useAmplitude from 'hooks/amplitude/use-amplitude';
import { useUpserviceWidgetContext } from 'providers';

import styles from './modal.module.scss';

const { Title } = Typography;

export const OnboardingModal = ({ visible, onClose }) => {
  const routes = useRoutesService();

  const amplitude = useAmplitude();
  const { openWidget } = useUpserviceWidgetContext();

  const logEvent = type =>
    amplitude.logEvent({
      event: STARTING_QUESTION,
      params: {
        question_type: type
      }
    });

  const items = [
    {
      description: (
        <Translation ns="Onboarding">{t => t('OrdersBtn')}</Translation>
      ),
      onClick: () => {
        logEvent('orders');
        routes.toContacts({ view: DEALS });
      }
    },
    {
      description: (
        <Translation ns="Onboarding">{t => t('TasksBtn')}</Translation>
      ),
      onClick: () => {
        logEvent('tasks');
        routes.toTasks();
      }
    },
    {
      description: (
        <Translation ns="Onboarding">{t => t('OtherBtn')}</Translation>
      ),
      onClick: () => {
        logEvent('other');
        openWidget();
      }
    }
  ];

  return (
    <Modal
      visible={visible}
      width={640}
      destroyOnClose
      onClose={onClose}
      centered
      contentClassName={styles.root}
    >
      <Title level={1} className={styles.title}>
        <Translation ns="Onboarding">
          {t => t('OnboardingQuestionHeading')}
        </Translation>
      </Title>

      {items.map((item, index) => (
        <Button
          key={`${index}-${item.description}`}
          type="secondary"
          onClick={() => {
            onClose();
            item.onClick();
          }}
          className={styles.button}
        >
          {item.description}
        </Button>
      ))}
    </Modal>
  );
};

OnboardingModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

OnboardingModal.defaultProps = {
  visible: false,
  onClose: () => {}
};

export default OnboardingModal;
