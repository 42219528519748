import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';

import {
  getIsUserLoading,
  getIsUserLoaded,
  getHasErrorUser,
  getIsOnlyUserProfile
} from 'store/user';
import {
  getHasErrorWorkspace,
  getIsCurrentWorkspaceLoaded,
  getIsCurrentWorkspaceLoading
} from 'store/workspace';
import { getIsOnlyCompanyProfile } from 'store/billing';

import Sider from './sider';
import Header from './header';
import LayoutTemplate from './template/layout-template';
import Content from './template/content';
import { initialLoadingAnimation } from '../icons';

const Layout = ({ children }) => {
  const isUserLoaded = useSelector(getIsUserLoaded);
  const isUserLoading = useSelector(getIsUserLoading);
  const isCurrentWorkspaceLoaded = useSelector(getIsCurrentWorkspaceLoaded);
  const isCurrentWorkspaceLoading = useSelector(getIsCurrentWorkspaceLoading);
  const hasErrorUser = useSelector(getHasErrorUser);
  const hasErrorWorkspace = useSelector(getHasErrorWorkspace);
  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);
  const isOnlyCompanyPofile = useSelector(getIsOnlyCompanyProfile);

  const hasError = hasErrorUser || hasErrorWorkspace;
  const isLoading =
    isUserLoading ||
    isCurrentWorkspaceLoading ||
    !isUserLoaded ||
    !isCurrentWorkspaceLoaded;

  const renderContent = useCallback(() => {
    if (hasError) return null;

    if (isLoading)
      return (
        <Lottie
          animationData={initialLoadingAnimation}
          loop
          style={{ width: '100%', height: 150, alignSelf: 'center' }}
        />
      );

    return children;
  }, [children, hasError, isLoading]);

  return (
    <>
      {!isOnlyUserProfile && !isOnlyCompanyPofile && <Sider />}

      <LayoutTemplate>
        <Header />

        <Content>{renderContent()}</Content>
      </LayoutTemplate>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Layout.defaultProps = {
  children: undefined
};

export default Layout;
