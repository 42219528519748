import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MODAL,
  CREATE_COMPANY,
  CHANNELS_CREATOR_DRAWER,
  CHANNELS_EDITOR_DRAWER,
  ASSIGNMENTS_CHANGE_DRAWER,
  RELATIONS_DRAWER,
  CONTACT_CREATOR_DRAWER,
  CONTRACT_DRAWER,
  ACT_EDITOR_DRAWER,
  WRITE_FIRST_DRAWER,
  SAVE_TO_DISK_DRAWER,
  INVITE_EMPLOYEE_DRAWER,
  INVITE_EMPLOYEE,
  CONTACTS_IMPORT_DRAWER,
  CONTACTS_IMPORT_RESULTS_DRAWER,
  MESSAGE_TEMPLATES_DRAWER,
  ASSET_CREATOR_DRAWER,
  TASK_CREATOR_DRAWER,
  CREATE_DEPARTMENT_DRAWER,
  MOVE_DEPARTMENT_DRAWER,
  REPLACE_EMPLOYEE_DRAWER,
  ASSET_SUBSCRIBERS_DRAWER,
  ATTACHMENT_SUBSCRIBERS_DRAWER
} from 'constants/index';

import CreateWorkspaceDrawer from 'components/common/create-workspace-drawer';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import TaskDetailDrawer from 'components/tasks-view/view/drawers/details';
import TaskEditorDrawer from 'components/tasks-view/view/drawers/editor';
import AssetDetailsDrawer from 'components/assets-view/drawers/details';
import useAssetDetailsVisible from 'components/assets-view/hooks/use-asset-details-visible';
import ChannelsCreatorDrawer from 'components/contacts-view/views/integrations/drawers/creator';
import ChannelsEditorDrawer from 'components/contacts-view/views/integrations/drawers/editor';
import {
  AssignmentsDrawer,
  RelationsDrawer,
  SaveToDiskDrawer
} from 'components/common/drawers';
import ContactCreatorDrawer from 'components/contacts-view/drawers/creator';
import OrderContractDrawer from 'components/orders-view/drawers/contract';
import OrderActEditorDrawer from 'components/orders-view/drawers/act';
import WriteFirstDrawer from 'components/contacts-view/drawers/write-first';
import InviteEmployeeDrawer from 'components/team-view/employees-view/drawers/invite-employee';
import ContactsImportDrawer from 'components/contacts-view/drawers/contacts-import';
import ContactsImportResultsDrawer from 'components/contacts-view/drawers/contacts-import-results';
import MessageTemplatesDrawer from 'components/common/drawers/message-templates';
import AssetCreatorDrawer from 'components/assets-view/drawers/creator';
import CreateDepartmentDrawer from 'components/team-view/orgstructure-view/drawers/create-department';
import MovingDepartmentDrawer from 'components/team-view/orgstructure-view/drawers/moving-department';
import ReplaceEmployeeDrawer from 'components/team-view/employees-view/drawers/replace-employee';
import useAssetDeletedVisible from 'components/assets-view/hooks/use-asset-deleted-visible';
import AssetDeletedDrawer from 'components/assets-view/drawers/deleted';
import AssetSubscribersDrawer from 'components/assets-view/drawers/asset-subscribers-drawer';
import AttachmentSubscribersDrawer from 'components/attachments-view/drawers/attachment-subscribers-drawer';

import { getDrawer, setVisibleDrawer } from 'store/drawers';
import { getIsOnlyUserProfile } from 'store/user';
import { getAllWorkspaces } from 'store/workspace';

import { useTaskCreator, useTaskDetailModal, useTaskEditor } from 'hooks';
import { useQueryParam } from 'hooks/common';
import useModalsService from 'services/modals';
import getActiveWorkspaces from 'utils/get-active-workspaces';

export const DrawersProvider = () => {
  const dispatch = useDispatch();

  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);
  const workspaces = useSelector(getAllWorkspaces);

  const contactCreatorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CONTACT_CREATOR_DRAWER })
  );
  const contactsImportDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CONTACTS_IMPORT_DRAWER })
  );
  const contactsImportResultsDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CONTACTS_IMPORT_RESULTS_DRAWER })
  );
  const channelsCreatorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CHANNELS_CREATOR_DRAWER })
  );
  const channelsEditorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CHANNELS_EDITOR_DRAWER })
  );
  const assignmentsChangeDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ASSIGNMENTS_CHANGE_DRAWER })
  );
  const relationsDrawer = useSelector(state =>
    getDrawer(state)({ drawer: RELATIONS_DRAWER })
  );
  const contractDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CONTRACT_DRAWER })
  );
  const orderActEditorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ACT_EDITOR_DRAWER })
  );
  const contactWriteFirstDrawer = useSelector(state =>
    getDrawer(state)({ drawer: WRITE_FIRST_DRAWER })
  );
  const saveToDiskDrawer = useSelector(state =>
    getDrawer(state)({ drawer: SAVE_TO_DISK_DRAWER })
  );
  const inviteEmployeeDrawer = useSelector(state =>
    getDrawer(state)({ drawer: INVITE_EMPLOYEE_DRAWER })
  );
  const messageTemplatesDrawer = useSelector(state =>
    getDrawer(state)({ drawer: MESSAGE_TEMPLATES_DRAWER })
  );
  const assetCreatorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ASSET_CREATOR_DRAWER })
  );
  const taskCreatorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: TASK_CREATOR_DRAWER })
  );
  const createDepartmentDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CREATE_DEPARTMENT_DRAWER })
  );
  const moveDepartmentDrawer = useSelector(state =>
    getDrawer(state)({ drawer: MOVE_DEPARTMENT_DRAWER })
  );
  const replaceEmployeeDrawer = useSelector(state =>
    getDrawer(state)({ drawer: REPLACE_EMPLOYEE_DRAWER })
  );
  const assetSubscribersDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ASSET_SUBSCRIBERS_DRAWER })
  );
  const attachmentSubscribersDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ATTACHMENT_SUBSCRIBERS_DRAWER })
  );

  const handleCloseDrawer = drawer => dispatch(setVisibleDrawer({ drawer }));

  const modals = useModalsService();
  const modal = useQueryParam(MODAL);

  const showCreateWorkspace = modal === CREATE_COMPANY;
  const showInviteEmployee = modal === INVITE_EMPLOYEE;

  const [visibleCreator] = useTaskCreator(false);
  const [visibleTaskDetail] = useTaskDetailModal(false);
  const [visibleEditor] = useTaskEditor(false);

  const assetDetailsVisible = useAssetDetailsVisible(false);
  const assetDeletedVisible = useAssetDeletedVisible(false);

  const onCloseDrawer = () => modals.close();
  const onBack = () => modals.back();

  return (
    <>
      <CreateWorkspaceDrawer visible={showCreateWorkspace} onClose={onBack} />

      {!isOnlyUserProfile && !!getActiveWorkspaces(workspaces).length && (
        <>
          {/* Создние задачи */}
          <TaskCreatorDrawer
            visible={!!taskCreatorDrawer || visibleCreator}
            value={taskCreatorDrawer}
            onClose={() => {
              if (visibleCreator) {
                return onCloseDrawer();
              }

              return handleCloseDrawer(TASK_CREATOR_DRAWER);
            }}
          />

          {/* Просмотр задачи */}
          <TaskDetailDrawer
            visible={visibleTaskDetail}
            onClose={onCloseDrawer}
          />

          {/* Редактирование задачи */}
          <TaskEditorDrawer visible={visibleEditor} onClose={onBack} />

          {/* CONTACTS */}
          <ContactCreatorDrawer
            visible={!!contactCreatorDrawer}
            values={contactCreatorDrawer}
            isDetailsAfterSubmit={
              (contactCreatorDrawer || {}).isDetailsAfterSubmit
            }
            onClose={() => handleCloseDrawer(CONTACT_CREATOR_DRAWER)}
          />

          <ContactsImportDrawer
            visible={!!contactsImportDrawer}
            data={contactsImportDrawer}
            onClose={() => handleCloseDrawer(CONTACTS_IMPORT_DRAWER)}
          />

          <ContactsImportResultsDrawer
            visible={!!contactsImportResultsDrawer}
            data={contactsImportResultsDrawer}
            onClose={() => handleCloseDrawer(CONTACTS_IMPORT_RESULTS_DRAWER)}
          />

          {/* WRITE FIRST DRAWER */}
          <WriteFirstDrawer
            visible={!!contactWriteFirstDrawer}
            data={contactWriteFirstDrawer}
            onClose={() => handleCloseDrawer(WRITE_FIRST_DRAWER)}
          />

          {/* MESSAGE TEMPLATES DRAWER */}
          <MessageTemplatesDrawer
            visible={!!messageTemplatesDrawer}
            data={messageTemplatesDrawer}
            onClose={() => handleCloseDrawer(MESSAGE_TEMPLATES_DRAWER)}
          />

          {/* CHANNELS */}
          <ChannelsCreatorDrawer
            visible={!!channelsCreatorDrawer}
            data={channelsCreatorDrawer}
            onClose={() => handleCloseDrawer(CHANNELS_CREATOR_DRAWER)}
          />

          <ChannelsEditorDrawer
            visible={!!channelsEditorDrawer}
            onClose={() => handleCloseDrawer(CHANNELS_EDITOR_DRAWER)}
          />

          {/* ASSETS */}
          <AssetCreatorDrawer
            visible={!!assetCreatorDrawer}
            values={assetCreatorDrawer}
            onClose={() => handleCloseDrawer(ASSET_CREATOR_DRAWER)}
          />

          <AssetDetailsDrawer
            visible={assetDetailsVisible}
            onClose={onCloseDrawer}
          />

          <AssetDeletedDrawer
            visible={assetDeletedVisible}
            onClose={onCloseDrawer}
          />

          <AssetSubscribersDrawer
            visible={!!assetSubscribersDrawer}
            data={assetSubscribersDrawer}
            onClose={() => handleCloseDrawer(ASSET_SUBSCRIBERS_DRAWER)}
          />

          {/* ATTACHMENTS */}

          <AttachmentSubscribersDrawer
            visible={!!attachmentSubscribersDrawer}
            data={attachmentSubscribersDrawer}
            onClose={() => handleCloseDrawer(ATTACHMENT_SUBSCRIBERS_DRAWER)}
          />

          {/* ASSIGNMENTS - used to change members in tasks, orders and setting access in contacts, assets */}
          <AssignmentsDrawer
            visible={!!assignmentsChangeDrawer}
            data={assignmentsChangeDrawer}
            onClose={() => handleCloseDrawer(ASSIGNMENTS_CHANGE_DRAWER)}
          />

          {/* RELATIONS */}
          <RelationsDrawer
            visible={!!relationsDrawer}
            data={relationsDrawer}
            onClose={() => handleCloseDrawer(RELATIONS_DRAWER)}
          />

          {/* ORDER CONTRACT */}
          <OrderContractDrawer
            visible={!!contractDrawer}
            data={contractDrawer}
            onClose={() => handleCloseDrawer(CONTRACT_DRAWER)}
          />

          {/* ORDER CHANGE ACT DRAWER */}
          <OrderActEditorDrawer
            visible={!!orderActEditorDrawer}
            data={orderActEditorDrawer}
            onClose={() => handleCloseDrawer(ACT_EDITOR_DRAWER)}
          />

          {/* SAVE TO DISK */}
          <SaveToDiskDrawer
            visible={!!saveToDiskDrawer}
            data={saveToDiskDrawer}
            onClose={() => handleCloseDrawer(SAVE_TO_DISK_DRAWER)}
          />

          <InviteEmployeeDrawer
            visible={!!inviteEmployeeDrawer || showInviteEmployee}
            data={inviteEmployeeDrawer}
            onClose={() => {
              if (showInviteEmployee) {
                return onCloseDrawer();
              }

              return handleCloseDrawer(INVITE_EMPLOYEE_DRAWER);
            }}
          />

          <CreateDepartmentDrawer
            visible={createDepartmentDrawer}
            onClose={() => handleCloseDrawer(CREATE_DEPARTMENT_DRAWER)}
            data={createDepartmentDrawer}
          />

          <MovingDepartmentDrawer
            visible={moveDepartmentDrawer}
            onClose={() => handleCloseDrawer(MOVE_DEPARTMENT_DRAWER)}
            data={moveDepartmentDrawer}
          />

          <ReplaceEmployeeDrawer
            visible={replaceEmployeeDrawer}
            onClose={() => handleCloseDrawer(REPLACE_EMPLOYEE_DRAWER)}
            data={replaceEmployeeDrawer}
          />
        </>
      )}
    </>
  );
};

export default DrawersProvider;
