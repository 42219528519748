import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { WORKSPACES } from 'constants/index';

import { getAllWorkspaces, getWorkspaceId } from 'store/workspace';

import { useChangedStorageFilter } from 'hooks/common/use-filter-storage';

const clearWorkspacesStorage = () =>
  Object.keys(window.localStorage)
    .filter(key => key === WORKSPACES)
    .map(key => window.localStorage.removeItem(key));

// stores companies in localStorage
// used to get values from the store
// and to collect data by a widget that is embedded in the application
export const useWorkspacesStorage = () => {
  const workspaceId = useSelector(getWorkspaceId);
  const workspaces = useSelector(getAllWorkspaces) || {};

  useChangedStorageFilter(WORKSPACES, workspaces, key => key);

  useEffect(() => {
    if (!Object.keys(workspaces).length) {
      clearWorkspacesStorage();
    }
  }, [workspaceId, workspaces]);
};

export default useWorkspacesStorage;
