import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Typography from 'components/common/typography';
import {
  FormAntdSelect,
  FormInput,
  FormRadio,
  LabelWithTooltip
} from 'components/common/hook-form';
import Button from 'components/common/button';

import { getUILanguage } from 'store/ui';

import { getValidEmails } from 'utils/validators';
import getHasOwnerRole from 'utils/get-has-owner-role';

import InviteList from './invite-list';
import {
  DEPARTMENT_DEFAULT_NAME,
  EMPLOYEES,
  getInvitationTypeImage,
  INVITATION_TYPE_OPTIONS,
  MANAGER,
  SUBORDINATES
} from './constants';

import styles from './invite-employee.module.scss';

const InviteEmployeeForm = ({
  afterVisible,
  currentEmployee,
  isLoading,
  onSubmit
}) => {
  const language = useSelector(getUILanguage);

  const { t } = useTranslation(['InviteCollegue', 'Errors']);

  const methods = useForm({
    defaultValues: {
      ivitationType: EMPLOYEES
    },
    shouldUnregister: true
  });

  const ivitationTypeWatch = useWatch({
    name: 'ivitationType',
    control: methods.control
  });

  const employeesWatch = useWatch({
    name: EMPLOYEES,
    control: methods.control
  });

  const subordinatesWatch = useWatch({
    name: SUBORDINATES,
    control: methods.control
  });

  const { id, department, roles } = currentEmployee;

  const isManager = department.managerId === id;
  const isOwner = getHasOwnerRole(roles);
  const hideDeprtmentField =
    !(subordinatesWatch || []).length ||
    (isManager && department.name !== DEPARTMENT_DEFAULT_NAME);

  const changeWithEmailValidate = (values, invitationType) => {
    const validEmails = getValidEmails(values);

    methods.setValue(invitationType, validEmails);
  };

  const getInvitationsTypeOptions = () => {
    if (isOwner) {
      return INVITATION_TYPE_OPTIONS;
    }

    return INVITATION_TYPE_OPTIONS.filter(o => o.value !== MANAGER);
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography.Paragraph weight="semibold">
          {t('ChooseWhoToInvite')}
        </Typography.Paragraph>

        <FormRadio
          name="ivitationType"
          options={getInvitationsTypeOptions()}
          className={styles.ivitationType}
        />

        {ivitationTypeWatch === MANAGER && (
          <FormInput
            label={t('YourImmediateSupervisor')}
            type="email"
            name={MANAGER}
            placeholder={t('EnterEmail')}
            rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
          />
        )}

        {ivitationTypeWatch === SUBORDINATES && (
          <>
            <FormAntdSelect
              name={SUBORDINATES}
              rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
              label={
                <LabelWithTooltip
                  label={t('YourImmediateSubordinates')}
                  tooltip={t('CollegueTip')}
                />
              }
              placeholder={t('EnterEmail')}
              mode="tags"
              notFoundContent={null}
              tokenSeparators={[',', ' ']}
              allowClear
              value={subordinatesWatch}
              onChange={v => changeWithEmailValidate(v, SUBORDINATES)}
            />

            {!hideDeprtmentField && (
              <FormInput
                name="department"
                label={t('YourDepartment')}
                placeholder={t('EnterDepartmentName')}
                rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
              />
            )}
          </>
        )}

        {ivitationTypeWatch === EMPLOYEES && (
          <FormAntdSelect
            name={EMPLOYEES}
            rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
            label={
              <LabelWithTooltip
                label={t('Collegue')}
                tooltip={t('CollegueTip')}
              />
            }
            placeholder={t('EnterEmail')}
            mode="tags"
            notFoundContent={null}
            tokenSeparators={[',', ' ']}
            allowClear
            value={employeesWatch}
            onChange={v => changeWithEmailValidate(v, EMPLOYEES)}
          />
        )}

        <img
          src={getInvitationTypeImage(language, ivitationTypeWatch)}
          alt={ivitationTypeWatch}
          className={styles.image}
        />

        <InviteList afterVisible={afterVisible} />

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          width="expanded"
          className={styles.submit}
          loading={isLoading}
        >
          {t('SubmitBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export default InviteEmployeeForm;
