import { EN_US, RU_RU, FR_FR, DE_DE, ES_ES, PL_PL, PT_PT } from 'i18n-config';

const map = {
  [RU_RU]: 'ru',
  [EN_US]: 'en',
  [FR_FR]: 'fr',
  [DE_DE]: 'de',
  [ES_ES]: 'es',
  [PL_PL]: 'pl',
  [PT_PT]: 'pt'
};

export const getIso2CodeByLanguage = ({ language, isEnToEng }) => {
  const lng = map[language];

  if (lng === 'en' && isEnToEng) {
    return 'eng';
  }

  return lng;
};

export default getIso2CodeByLanguage;
