import { combineActions } from 'redux-actions';

import {
  UPDATE_EVENT_COUNTER_TREE,
  WORKSPACE,
  EMPLOYEE
} from 'constants/index';

import transformDataSource from 'components/team-view/employees-view/employee-view/notificactions/utils';

import { logout, login, updateUser } from 'store/user';
import { joinDepartment, becomeOwner } from 'store/team/departments';
import {
  addPaymentAddon,
  addPaymentSubscription,
  cancelPaymentSubscription,
  changePaymentSubscription,
  deletePaymentAddon,
  fetchPaymentAccount,
  partialUpdatePaymentAccount,
  retryLastPaymentTransaction
} from 'store/billing';
import { combineActionsThunk } from 'store/actions-thunk';

import handleActions from 'utils/redux-actions';
import { indexWorkspaces } from 'utils/index-data';
import getActiveWorkspaces from 'utils/get-active-workspaces';

import {
  setActiveId,
  updateWorkspace,
  deleteWorkspaceCity,
  createWorkspaceCity,
  createWorkspaceCategory,
  deleteWorkspaceCategory,
  fetchWorkspace,
  createWorkspace,
  updateWorkspaceUser,
  updateWorkspaceUserCurrency,
  setWorkspaceUserPriceVisible,
  fetchWorkspaces,
  fetchWorkspacesSkipLoading,
  checkJoining,
  setSelectedId,
  updateWorkspaceUserSubscription,
  createPublicContract,
  fetchNotificationSettings,
  setNotificationSettings,
  deleteWorkspace,
  updateWorkspaceLegatInfo,
  fetchPersonalWorkspace,
  fetchCurrentWorkspace
} from './actions';

const initialState = {
  isLoaded: false,
  isLoading: false,
  isCurrentWorkspaceLoading: false,
  isCurrentWorkspaceLoaded: false,
  error: undefined,
  activeId: undefined,
  personal: {},
  entities: {},
  events: {},
  joining: {
    assignee: undefined,
    createdAt: undefined,
    value: undefined
  }
};

export default handleActions(
  {
    [combineActions(logout, login)]: () => initialState,

    [setSelectedId]: (state, { payload }) => {
      state.selectedId = payload;

      return state;
    },

    [setActiveId]: (state, { payload }) => {
      state.activeId = payload;
      state.joining = {};

      localStorage.setItem('workspace', payload);

      return state;
    },

    [fetchWorkspaces.START]: state => {
      state.isLoading = true;

      return state;
    },

    [fetchPersonalWorkspace.SUCCEEDED]: (state, { payload }) => {
      state.personal = { ...payload.workspace, title: 'Profile' };

      if (payload.isCurrent) {
        state.isCurrentWorkspaceLoaded = true;
        state.isCurrentWorkspaceLoading = false;
      }

      return state;
    },

    [combineActions(
      fetchWorkspaces.SUCCEEDED,
      fetchWorkspacesSkipLoading.SUCCEEDED
    )]: (state, { payload, args = {} }) => {
      const { entities, activeId } = indexWorkspaces(payload);

      state.isCurrentWorkspaceLoaded = true;
      state.isCurrentWorkspaceLoading = false;

      state.isLoading = false;
      state.isLoaded = true;

      state.entities = entities;
      state.activeId = activeId || args.personalWorkspaceId;

      return state;
    },

    [fetchWorkspaces.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [combineActionsThunk(
      updateWorkspace.SUCCEEDED,
      updateWorkspaceLegatInfo.SUCCEEDED
    )]: (state, { payload }) => {
      Object.keys(payload).forEach(key => {
        state.entities[payload.id][key] = payload[key];
      });

      return state;
    },

    [createWorkspace.SUCCEEDED]: (state, { payload }) => {
      state.entities[payload.id] = payload;
      state.activeId = payload.id;

      return state;
    },

    [fetchWorkspace.SUCCEEDED]: (state, { payload }) => {
      state.entities[payload.id] = payload;

      return state;
    },

    [fetchCurrentWorkspace.START]: state => {
      state.isCurrentWorkspaceLoading = true;
      state.isCurrentWorkspaceLoaded = false;
    },

    [fetchCurrentWorkspace.SUCCEEDED]: (state, { payload }) => {
      if (payload && getActiveWorkspaces([payload]).length) {
        state.entities[payload.id] = payload;
        state.activeId = payload.id;

        state.isCurrentWorkspaceLoading = false;
        state.isCurrentWorkspaceLoaded = true;
      }

      return state;
    },

    [updateUser.SUCCEEDED]: (state, { payload }) => {
      Object.keys(state.entities).forEach(key => {
        const userId = state.entities[key].user.id;
        state.entities[key].user = {
          ...state.entities[key].user,
          ...payload,
          email: state.entities[key].user.email,
          id: userId
        };

        if (state.entities[key].isIndividual) {
          // обновление лого компании частного лица
          state.entities[key].logoFile = payload.avatarFile;
        }
      });

      return state;
    },

    [updateWorkspaceUser.SUCCEEDED]: (state, { payload }) => {
      Object.keys(payload).forEach(key => {
        state.entities[payload.workspace].user[key] = payload[key];
      });

      return state;
    },

    [updateWorkspaceUserCurrency.SUCCEEDED]: (state, { payload }) => {
      const { currency, workspaceId } = payload;

      state.entities[workspaceId].user.currency = currency;

      return state;
    },

    [setWorkspaceUserPriceVisible.SUCCEEDED]: (state, { payload }) => {
      const { visible, workspaceId } = payload;

      // TODO: сохранять это в localStorage
      state.entities[workspaceId].user.userPriceVisible = visible;

      return state;
    },

    [createWorkspaceCategory.SUCCEEDED]: (state, { payload }) => {
      const { workspaceId, ...categories } = payload;
      Object.keys(categories).forEach(key => {
        if (state.entities[workspaceId].isIndividual) {
          state.entities[workspaceId].user.categories.push(categories[key]);
        }
        state.entities[workspaceId].categories.push(categories[key]);
      });

      return state;
    },

    [deleteWorkspaceCategory.SUCCEEDED]: (
      state,
      { payload: { workspaceId }, args: { categories } }
    ) => {
      categories.forEach(categoryId => {
        const workspace = state.entities[workspaceId];

        const indexCategory = workspace.categories.findIndex(
          c => c.category === categoryId
        );

        const filtredCategories = workspace.categories.filter(
          (item, index) => index !== indexCategory
        );
        state.entities[workspaceId].categories = filtredCategories;

        if (workspace.isIndividual) {
          state.entities[
            workspaceId
          ].user.categories = workspace.user.categories.filter(
            (item, index) => index !== indexCategory
          );
        }
      });

      return state;
    },

    [createWorkspaceCity.SUCCEEDED]: (state, { payload }) => {
      state.entities[payload.workspaceId].cities.push(payload);

      return state;
    },

    [deleteWorkspaceCity.SUCCEEDED]: (
      state,
      { payload: { workspaceId }, args: { cityId } }
    ) => {
      const indexCity = state.entities[workspaceId].cities.findIndex(
        c => c.city === cityId
      );
      delete state.entities[workspaceId].cities[indexCity];

      return state;
    },

    [checkJoining.SUCCEEDED]: (state, { payload }) => {
      state.joining = payload;

      return state;
    },

    [combineActionsThunk(joinDepartment.SUCCEEDED, becomeOwner.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      state.joining = {
        assignee: payload.assignee,
        createdAt: payload.createdAt,
        value: true
      };

      return state;
    },

    [UPDATE_EVENT_COUNTER_TREE]: (state, { payload }) => {
      state.events = payload;

      return state;
    },

    [updateWorkspaceUserSubscription]: (state, { payload }) => {
      const CATEGORY = 'category';
      const REGION = 'region';

      const workspace = state.entities[state.activeId];

      const prepareData = (data, dataKey, fieldKey) =>
        data.map(dataItem => ({
          [fieldKey]:
            fieldKey === WORKSPACE ? state.activeId : workspace.user.id,
          [dataKey]: dataItem
        }));

      const updatedWorkspace = {
        ...workspace,
        categories: prepareData(payload.categories, CATEGORY, WORKSPACE),
        regions: prepareData(payload.regions, REGION, WORKSPACE),
        user: {
          ...workspace.user,
          ...payload,
          categories: prepareData(payload.categories, CATEGORY, EMPLOYEE),
          regions: prepareData(payload.regions, REGION, EMPLOYEE)
        }
      };

      state.entities = {
        ...state.entities,
        [state.activeId]: { ...updatedWorkspace }
      };

      return state;
    },

    [createPublicContract.SUCCEEDED]: (state, { payload }) => {
      Object.keys(payload).forEach(key => {
        state.entities[payload.id][key] = payload[key];
      });

      return state;
    },

    [combineActionsThunk(
      fetchNotificationSettings.SUCCEEDED,
      setNotificationSettings.SUCCEEDED
    )]: (state, { payload }) => {
      state.entities[payload.workspaceId].settings = transformDataSource(
        payload.settings
      );

      return state;
    },

    [combineActionsThunk(deleteWorkspace.SUCCEEDED)]: (state, { payload }) => {
      state.entities[payload.workspaceId].isArchived = payload.isArchived;

      return state;
    },

    [combineActionsThunk(
      fetchPaymentAccount.SUCCEEDED,
      partialUpdatePaymentAccount.SUCCEEDED,
      retryLastPaymentTransaction.SUCCEEDED,
      addPaymentSubscription.SUCCEEDED,
      changePaymentSubscription.SUCCEEDED,
      cancelPaymentSubscription.SUCCEEDED,
      addPaymentAddon.SUCCEEDED
    )]: (state, { payload }) => {
      state.entities[payload.workspaceId].account = payload.account;

      return state;
    },

    [deletePaymentAddon.SUCCEEDED]: (state, { payload }) => {
      state.entities[payload.workspaceId].account.addons = state.entities[
        payload.workspaceId
      ].account.addons.map(a => {
        if (a.id === payload.addonId) {
          return { ...a, nextPeriodActive: false };
        }

        return a;
      });

      return state;
    }
  },
  initialState
);
