import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'qs';

import {
  CONTACTS,
  REDIRECT,
  TASKS,
  PROJECTS,
  BACKLOG,
  STATUS_IN_WORK,
  SPRINTS,
  CONTRACTORS,
  DIALOGUES,
  ASSETS,
  CHATS,
  CATEGORIES,
  DASHBOARD,
  NOTIFICATIONS,
  SETTINGS_PERSONAL,
  // SETTINGS,
  // CONTROLLERS,
  CONTACT_INFO,
  BILLING,
  TAB_LINK,
  FUNNELS_SETTINGS,
  ALL
} from 'constants/index';

import { getWorkspaceId } from 'store/workspace';
import { getUser } from 'store/user';

// TODO: добавить оптимизацию useCallback useMemo
export const useRoutesService = ({
  returnUrl = false,
  withOrigin = false
} = {}) => {
  const history = useHistory();
  const location = useLocation();

  const currentWorkspaceId = useSelector(getWorkspaceId);
  const user = useSelector(getUser);

  const onPush = (locationData, state, isReplace) => {
    const url =
      typeof locationData === 'string' ? locationData : locationData.pathname;

    if (returnUrl) {
      return withOrigin ? `${window.location.origin}${url}` : url;
    }

    return isReplace
      ? history.replace(locationData, state)
      : history.push(locationData, state);
  };

  return {
    to: (locationData, state) => onPush(locationData, state),
    toDefaultPage: ({ workspaceId, state } = {}) => {
      const url = `/${workspaceId ||
        currentWorkspaceId}/${DASHBOARD}/${NOTIFICATIONS}`;

      return onPush(url, state);
    },
    toDashboard: ({ view = NOTIFICATIONS, state } = {}) => {
      const url = `/${currentWorkspaceId}/${DASHBOARD}/${view}`;

      return onPush(url, state);
    },
    toLogin: ({ search } = {}) =>
      onPush({
        pathname: '/auth/login',
        search: search ? `${REDIRECT}=${encodeURIComponent(search)}` : undefined
      }),
    toRegistration: () => onPush('/auth/registration'),
    toRegisterFinish: state => onPush('/auth/registration/finish', state),
    toRegisterFinishToken: (uid, token) =>
      onPush(`/auth/registration/finish/${uid}/${token}`),
    toResetPassword: () => onPush('/auth/password/reset'),

    toEngineeringWorks: () =>
      onPush({
        pathname: '/engineering-works',
        search: `${REDIRECT}=${encodeURIComponent(window.location.href)}`
      }),
    toSelectWorksapce: (state, isReplace) => {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (query[REDIRECT]) {
        window.location = query[REDIRECT];
        return;
      }

      onPush('/auth/select-workspace', state, isReplace);
    },
    toContacts: ({ view = CHATS } = {}) => {
      const url = `/${currentWorkspaceId}/${CONTACTS}/${view}`;
      return onPush(url);
    },
    toContact: ({ id, state, isReplace, view = TAB_LINK.INFO }) => {
      const url = `/${currentWorkspaceId}/${CONTACTS}/${CHATS}/${id}/${view}`;
      return onPush(url, state, isReplace);
    },
    toNewOrderStatus: ({
      contactId,
      orderStatusId,
      view = TAB_LINK.FUNNELS,
      state,
      isReplace
    }) => {
      const url = `/${currentWorkspaceId}/${CONTACTS}/${CHATS}/${contactId}/${view}/${orderStatusId}`;
      return onPush(url, state, isReplace);
    },
    toContractor: id => {
      const url = `/${currentWorkspaceId}/${CONTACTS}/${CONTRACTORS}/${id}`;
      return onPush(url);
    },
    toProjects: () => {
      const url = `/${currentWorkspaceId}/${TASKS}/${PROJECTS}`;
      return onPush(url);
    },
    toProject: id => {
      const url = `/${currentWorkspaceId}/${TASKS}/${PROJECTS}/${id}/progress`;
      return onPush(url);
    },
    toTasks: ({ search, workspaceId } = {}, state) => {
      const pathname = `/${workspaceId || currentWorkspaceId}/${TASKS}/all`;
      return onPush({ pathname, search }, state);
    },
    toAssets: ({
      view = CATEGORIES,
      asset,
      category,
      showAssingManagers
    } = {}) => {
      const url = `/${currentWorkspaceId}/${ASSETS}/${view}`;
      return onPush(url, { asset, category, showAssingManagers });
    },
    toCategory: ({ view = CATEGORIES, id } = {}) => {
      const url = `/${currentWorkspaceId}/${ASSETS}/${view}/${id}`;
      return onPush(url);
    },
    toInProgress: projectId => {
      const url = `/${currentWorkspaceId}/${TASKS}/${PROJECTS}/${projectId}/${STATUS_IN_WORK}`;
      return onPush(url);
    },
    toBacklog: projectId => {
      const url = `/${currentWorkspaceId}/${TASKS}/${PROJECTS}/${projectId}/${BACKLOG}`;
      return onPush(url);
    },
    toSprints: projectId => {
      const url = `/${currentWorkspaceId}/${TASKS}/${PROJECTS}/${projectId}/${SPRINTS}`;
      return onPush(url);
    },
    toSprint: ({ projectId, sprintId }) => {
      const url = `/${currentWorkspaceId}/${TASKS}/${PROJECTS}/${projectId}/${SPRINTS}/${sprintId}`;
      return onPush(url);
    },
    toHierarchy: taskId =>
      onPush(`/${currentWorkspaceId}/${TASKS}/hierarchy/${taskId}`),

    toFunnelsSettings: ({ workspaceId } = {}) => {
      const url = `/${workspaceId ||
        currentWorkspaceId}/${CONTACTS}/${FUNNELS_SETTINGS}`;

      return onPush(url);
    },

    toOrderStatusFromLog: ({ state, orderStatusId, isReplace } = {}) => {
      const url = `/${currentWorkspaceId}/${CONTACTS}/${orderStatusId}`;

      return onPush(url, state, isReplace);
    },

    // TODO: check
    toDialog: id => {
      const url = `/${currentWorkspaceId}/${DIALOGUES}/${id}`;

      return onPush(url);
    },

    toIdeas: () => onPush(`/${currentWorkspaceId}/ideas`),

    toEmployee: (id, view = 'contact-info') =>
      onPush(`/${currentWorkspaceId}/team/orgstructure/${id}/${view}`),
    toEmployees: () => onPush(`/${currentWorkspaceId}/team/employees`),
    toOrgstructure: () => onPush(`/${currentWorkspaceId}/team/orgstructure`),
    toUserProfile: ({ workspaceId, view = SETTINGS_PERSONAL } = {}) =>
      onPush(
        `/${workspaceId ||
          currentWorkspaceId ||
          user.personalWorkspaceId}/settings/user-profile/${view}`
      ),
    toSettingsSecurity: () =>
      onPush(`/${currentWorkspaceId}/settings/user-profile/security`),
    toUserWorkspace: ({ employeeId, view = CONTACT_INFO }) =>
      onPush(
        `/${currentWorkspaceId}/settings/workspace-profile/user/${employeeId}/${view}`
      ),

    toBilling: ({ id = currentWorkspaceId, workspaceId, view } = {}) => {
      const viewUrl = view ? `/${view}` : '';
      const currentWorkspace = workspaceId || currentWorkspaceId;

      return onPush(
        `/${currentWorkspace}/settings/workspace-profile/workspace/${id}/${BILLING}${viewUrl}`
      );
    },
    toCompany: ({ id = currentWorkspaceId, view, state, workspaceId }) => {
      const viewUrl = view ? `/${view}` : '/contact-info';
      const currentWorkspace = workspaceId || currentWorkspaceId;

      return onPush(
        `/${currentWorkspace}/settings/workspace-profile/workspace/${id}${viewUrl}`,
        state
      );
    },

    toReports: () => onPush(`/${currentWorkspaceId}/reports/all`),

    toReport: ({ id }) => onPush(`/${currentWorkspaceId}/reports/all/${id}`),

    toReportTemplate: ({ id }) =>
      onPush(`/${currentWorkspaceId}/reports/templates/${id}`),

    toAttachments: (view = ALL) =>
      onPush(`/${currentWorkspaceId}/attachments/${view}`)
  };
};

export default useRoutesService;
