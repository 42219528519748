export const MAX_SIZE_LOGO = 1 * 1024 * 1024;

export const translations = {
  ru: {
    privacyPolicy:
      'Соглашаюсь на обработку персональных данных и принимаю условия ',
    privacyPolicyTextLink: 'политики.',
    privacyPolicyLink: 'https://upservice.com/privacy-policy',
    fieldRequired: 'Обязательное поле',
    phoneFieldRequired: 'Введите корректный номер телефона.',
    businessFormText: 'Бизнес форма ',
    formUnavailableTitle: 'Форма недоступна',
    formUnavailableDescription:
      'Администратор отключил или удалил данную форму',
    phone: 'Телефон',
    name: 'Имя',
    message: 'Ваше сообщение',
    email: 'Email',
    sendAnotherBtn: 'Заполнить повторно',
    errorMessage: 'Что-то пошло не так...',
    file: 'Добавить файл',
    tryUpdatePage: 'Попробуйте обновить страницу'
  },
  eng: {
    privacyPolicy:
      'I agree to the processing of personal data and accept the terms of the ',
    privacyPolicyTextLink: 'policy.',
    privacyPolicyLink: 'https://upservice.com/privacy-policy-eng',
    fieldRequired: 'This field is required',
    phoneFieldRequired: 'Enter the correct phone number.',
    businessFormText: 'Form created by ',
    formUnavailableTitle: 'Unavailable form',
    formUnavailableDescription:
      'Administrator has disabled or deleted this form',
    phone: 'Phone number',
    name: 'Name',
    message: 'Your message',
    email: 'Email',
    sendAnotherBtn: 'Send another one',
    errorMessage: 'Something went wrong...',
    file: 'Add file',
    tryUpdatePage: 'Please try refreshing the page'
  },
  de: {
    privacyPolicy:
      'Ich stimme der Verarbeitung personenbezogener Daten zu und akzeptiere die Bedingungen der ',
    privacyPolicyTextLink: 'Richtlinie.',
    privacyPolicyLink: 'https://upservice.com/privacy-policy-eng',
    fieldRequired: 'Dieses Feld ist erforderlich',
    phoneFieldRequired: 'Geben Sie die korrekte Telefonnummer ein.',
    businessFormText: 'Formular erstellt von ',
    formUnavailableTitle: 'Formular nicht verfügbar',
    formUnavailableDescription:
      'Der Administrator hat dieses Formular deaktiviert oder gelöscht',
    phone: 'Telefonnummer',
    name: 'Name',
    message: 'Ihre Nachricht',
    email: 'E-Mail',
    sendAnotherBtn: 'Nochmals senden',
    errorMessage: 'Etwas ist schief gelaufen...',
    file: 'Datei hinzufügen',
    tryUpdatePage: 'Bitte versuchen Sie, die Seite zu aktualisieren'
  },
  fr: {
    privacyPolicy:
      "J'accepte le traitement des données personnelles et les termes de la ",
    privacyPolicyTextLink: 'politique.',
    privacyPolicyLink: 'https://upservice.com/privacy-policy-eng',
    fieldRequired: 'Ce champ est requis',
    phoneFieldRequired: 'Entrez un numéro de téléphone valide.',
    businessFormText: 'Formulaire créé par ',
    formUnavailableTitle: 'Formulaire indisponible',
    formUnavailableDescription:
      "L'administrateur a désactivé ou supprimé ce formulaire",
    phone: 'Numéro de téléphone',
    name: 'Nom',
    message: 'Votre message',
    email: 'E-mail',
    sendAnotherBtn: 'Envoyer un autre',
    errorMessage: "Une erreur s'est produite...",
    file: 'Ajouter un fichier',
    tryUpdatePage: 'Veuillez actualiser la page'
  },
  es: {
    privacyPolicy:
      'Acepto el procesamiento de datos personales y los términos de la ',
    privacyPolicyTextLink: 'política.',
    privacyPolicyLink: 'https://upservice.com/privacy-policy-eng',
    fieldRequired: 'Este campo es obligatorio',
    phoneFieldRequired: 'Ingrese un número de teléfono válido.',
    businessFormText: 'Formulario creado por ',
    formUnavailableTitle: 'Formulario no disponible',
    formUnavailableDescription:
      'El administrador ha desactivado o eliminado este formulario',
    phone: 'Número de teléfono',
    name: 'Nombre',
    message: 'Su mensaje',
    email: 'Correo electrónico',
    sendAnotherBtn: 'Enviar otro',
    errorMessage: 'Algo salió mal...',
    file: 'Agregar archivo',
    tryUpdatePage: 'Por favor, actualice la página'
  },
  pt: {
    privacyPolicy: 'Aceito o processamento de dados pessoais e os termos da ',
    privacyPolicyTextLink: 'política.',
    privacyPolicyLink: 'https://upservice.com/privacy-policy-eng',
    fieldRequired: 'Este campo é obrigatório',
    phoneFieldRequired: 'Digite um número de telefone válido.',
    businessFormText: 'Formulário criado por ',
    formUnavailableTitle: 'Formulário indisponível',
    formUnavailableDescription:
      'O administrador desativou ou excluiu este formulário',
    phone: 'Número de telefone',
    name: 'Nome',
    message: 'Sua mensagem',
    email: 'E-mail',
    sendAnotherBtn: 'Enviar outro',
    errorMessage: 'Algo deu errado...',
    file: 'Adicionar arquivo',
    tryUpdatePage: 'Por favor, atualize a página'
  },
  pl: {
    privacyPolicy:
      'Wyrażam zgodę na przetwarzanie danych osobowych i akceptuję warunki ',
    privacyPolicyTextLink: 'polityki.',
    privacyPolicyLink: 'https://upservice.com/privacy-policy-eng',
    fieldRequired: 'To pole jest wymagane',
    phoneFieldRequired: 'Wprowadź poprawny numer telefonu.',
    businessFormText: 'Formularz utworzony przez ',
    formUnavailableTitle: 'Formularz niedostępny',
    formUnavailableDescription:
      'Administrator wyłączył lub usunął ten formularz',
    phone: 'Numer telefonu',
    name: 'Imię',
    message: 'Twoja wiadomość',
    email: 'E-mail',
    sendAnotherBtn: 'Wyślij ponownie',
    errorMessage: 'Coś poszło nie tak...',
    file: 'Dodaj plik',
    tryUpdatePage: 'Spróbuj odświeżyć stronę'
  }
};
