import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ENVIRONMENT_PROD } from 'constants/index';

import { getActiveWorkspace, getWorkspaceId } from 'store/workspace';

import { config } from 'config';

const isProduction = config.REACT_APP_ENVIRONMENT === ENVIRONMENT_PROD;

const growthbook = new GrowthBook({
  apiHost: config.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: config.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: !isProduction
});

growthbook.init({ streaming: true });

const GrowthBookClientProvider = ({ children }) => {
  const workspaceId = useSelector(getWorkspaceId);
  const workspace = useSelector(getActiveWorkspace);

  const { createdAt: workspaceCreatedAt } = workspace || {};

  useEffect(() => {
    growthbook.setAttributes({
      workspaceId,
      createdAt: workspaceCreatedAt
    });
  }, [workspaceId]);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

export default GrowthBookClientProvider;
