import React from 'react';

const DescriptionIcon = () => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clipPlus)">
      <path
        d="M20.5713 0.000488281H3.42843C2.95432 0.000488281 2.57129 0.383524 2.57129 0.857631V23.1433C2.57129 23.6175 2.95432 24.0005 3.42843 24.0005H20.5713C21.0454 24.0005 21.4284 23.6175 21.4284 23.1433V0.857631C21.4284 0.383524 21.0454 0.000488281 20.5713 0.000488281ZM19.4999 22.0719H4.49986V16.688H7.12218C7.4329 17.5666 7.97933 18.3567 8.70522 18.9567C9.62933 19.7201 10.7999 20.1433 11.9999 20.1433C13.1999 20.1433 14.3704 19.7228 15.2945 18.9567C16.0204 18.3567 16.5668 17.5666 16.8775 16.688H19.4999V15.0005H15.5249L15.3856 15.6621C15.0641 17.2639 13.6391 18.4291 11.9999 18.4291C10.3606 18.4291 8.93558 17.2639 8.61147 15.6621L8.47218 15.0005H4.49986V1.92906H19.4999V22.0719ZM6.857 7.42013H17.1427C17.2606 7.42013 17.357 7.3237 17.357 7.20585V5.92013C17.357 5.80227 17.2606 5.70585 17.1427 5.70585H6.857C6.73915 5.70585 6.64272 5.80227 6.64272 5.92013V7.20585C6.64272 7.3237 6.73915 7.42013 6.857 7.42013ZM6.857 11.7058H17.1427C17.2606 11.7058 17.357 11.6094 17.357 11.4916V10.2058C17.357 10.088 17.2606 9.99156 17.1427 9.99156H6.857C6.73915 9.99156 6.64272 10.088 6.64272 10.2058V11.4916C6.64272 11.6094 6.73915 11.7058 6.857 11.7058Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clipPlus">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default DescriptionIcon;
