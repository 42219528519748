import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { goBack } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import {
  TASKS,
  TEAM,
  CONTACTS,
  ASSETS,
  DASHBOARD,
  REPORTS
} from 'constants/index';

import { setWithoutDefaultFilter, getRouterUrlView } from 'store/router';

import useRoutesService from 'services/routes';

import Button from '../button';

export const BackBtn = ({ children, tooltipProps, onClick, ...props }) => {
  const dispatch = useDispatch();

  const { key } = useLocation();
  const routes = useRoutesService();

  const view = useSelector(getRouterUrlView);

  const goToByView = {
    [TASKS]: routes.toTasks,
    [TEAM]: routes.toOrgstructure,
    [CONTACTS]: routes.toContacts,
    [ASSETS]: routes.toAssets,
    [DASHBOARD]: routes.toDashboard,
    [REPORTS]: routes.toReports
  };

  const onGoBack = () => {
    if (!key) {
      return goToByView[view]();
    }

    return dispatch(goBack());
  };

  const handleClick = () => {
    dispatch(setWithoutDefaultFilter(true));

    if (onClick) {
      onClick();
    } else {
      onGoBack();
    }

    return setTimeout(() => {
      dispatch(setWithoutDefaultFilter(false));
    }, 500);
  };

  if (!goToByView[view] && !key && !onClick) {
    return null;
  }

  return (
    <Tooltip mouseEnterDelay={0.5} {...tooltipProps}>
      <Button icon="left" type="link" onClick={handleClick} {...props}>
        {children}
      </Button>
    </Tooltip>
  );
};

BackBtn.propTypes = {
  tooltipProps: PropTypes.object,
  onClick: PropTypes.func
};

BackBtn.defaultProps = {
  tooltipProps: {},
  onClick: undefined
};

export default BackBtn;
