import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollgridPlugin from '@fullcalendar/scrollgrid';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CALENDAR_VIEW_RESOURCE_TIME_GRID_DAY,
  CALENDAR_VIEW_TIME_GRID_WEEK
} from 'constants/index';

import EventContent from 'components/dashboard-view/views/calendar/event-content';
import DayHeader from 'components/dashboard-view/views/calendar/day-header';
import Typography from 'components/common/typography';
import RecordDetailsModal from 'components/dashboard-view/views/calendar/modals/record-details';

import useMeetingCalendar from './use-meeting-calendar';

import styles from './meeting-calendar.module.scss';

export const MeeteingCalendarContext = createContext();

const MeetingCalendar = ({ visible, offsetWidth, parentTaskId, children }) => {
  const {
    ref,
    resources,
    selectedDates,
    isLoading,
    records,
    openedRecord,
    locale,
    onClickRecord,
    goToDate,
    setDates,
    setSelectedDates,
    selectDatesToCalendar,
    setResources
  } = useMeetingCalendar({ visible, parentTaskId });

  const { t } = useTranslation(['AddTask', 'Common']);

  return (
    <>
      <div
        className={classnames(styles.root, {
          [styles.hidden]: !visible,
          [styles.loading]: isLoading
        })}
        style={{
          width: visible ? `calc(100% - ${offsetWidth}px)` : 0
        }}
      >
        <div className={styles.wrapper}>
          <Spin spinning={isLoading} className={styles.spin} />

          <FullCalendar
            schedulerLicenseKey="0489380832-fcs-1645101253"
            ref={ref}
            allDaySlot={false}
            unselectAuto={false}
            slotDuration="00:15:00"
            nowIndicator
            eventContent={props => <EventContent {...props} />}
            locale={locale}
            firstDay={1}
            plugins={[
              interactionPlugin,
              timeGridPlugin,
              resourceTimeGridPlugin,
              scrollgridPlugin
            ]}
            height="100%"
            initialView={CALENDAR_VIEW_RESOURCE_TIME_GRID_DAY}
            eventClassNames={styles.event}
            views={{
              resourceTimeGridDay: {
                slotLabelFormat: {
                  hour: 'numeric',
                  minute: '2-digit'
                },
                slotLabelInterval: '01:00',
                dayMinWidth: 200
              },
              timeGridWeek: {
                slotLabelFormat: {
                  hour: 'numeric',
                  minute: '2-digit'
                },
                selectable: true,
                slotLabelInterval: '01:00',
                dayHeaderContent: (props) => <DayHeader {...props} locale={locale} />
              }
            }}
            headerToolbar={{
              left: 'today prev,next title',
              center: '',
              right: `${CALENDAR_VIEW_RESOURCE_TIME_GRID_DAY},${CALENDAR_VIEW_TIME_GRID_WEEK}`
            }}
            buttonText={{
              day: t('Day'),
              week: t('Week'),
              today: t('Today', { ns: 'Common' })
            }}
            resourceLabelContent={({ resource }) => (
              <Typography.Text color="black-45" size="small">
                {resource.title}
              </Typography.Text>
            )}
            resources={resources}
            events={records}
            datesSet={setDates}
            select={setSelectedDates}
            eventClick={onClickRecord}
          />
        </div>
      </div>

      <MeeteingCalendarContext.Provider
        value={{
          selectedDates,
          goToDate,
          selectDatesToCalendar,
          setResources
        }}
      >
        {children}
      </MeeteingCalendarContext.Provider>

      <RecordDetailsModal openedRecord={openedRecord} />
    </>
  );
};

MeetingCalendar.propTypes = {
  visible: PropTypes.bool,
  offsetWidth: PropTypes.number
};

MeetingCalendar.defaultProps = {
  visible: false,
  offsetWidth: 620
};

export default MeetingCalendar;
