import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

import {
  OWNER,
  EMPLOYEE,
  ADMIN,
  BENCH,
  MANAGER,
  MAIN,
  CONTROLLER
} from '../../constants';

export const getWorkspaces = state => state.workspace;

export const getWorkspaceId = createSelector(
  getWorkspaces,
  workspace => workspace.activeId
);

export const getHasErrorWorkspace = createSelector(
  getWorkspaces,
  workspace => !!workspace.error
);

export const getSelectedId = createSelector(
  getWorkspaces,
  workspace => workspace.selectedId
);

export const getAllWorkspaces = createSelector(
  getWorkspaces,
  workspace => workspace.entities
);

export const getPersonalWorkspace = createSelector(
  getWorkspaces,
  workspace => workspace.personal
);

export const getCompanyWorkspaces = createSelector(getAllWorkspaces, entities =>
  Object.values(entities).filter(w => !w.isIndividual)
);

export const getIsWorkspacesLoading = createSelector(
  getWorkspaces,
  workspace => workspace.isLoading
);

export const getIsWorkspacesLoaded = createSelector(
  getWorkspaces,
  workspace => workspace.isLoaded
);

export const getIsCurrentWorkspaceLoaded = createSelector(
  getWorkspaces,
  workspace => workspace.isCurrentWorkspaceLoaded
);

export const getIsCurrentWorkspaceLoading = createSelector(
  getWorkspaces,
  workspace => workspace.isCurrentWorkspaceLoading
);

export const getIndividualWorkspaceId = createSelector(
  getAllWorkspaces,
  entities => (Object.values(entities).find(w => w.isIndividual) || {}).id
);

export const getActiveWorkspace = createSelector(
  [getWorkspaces, getWorkspaceId],
  (workspaces, activeId) => workspaces.entities[activeId]
);

export const getIsIndividual = createSelector(getActiveWorkspace, workspace =>
  workspace ? workspace.isIndividual : true
);

export const getWorkspacePermissions = createSelector(
  getActiveWorkspace,
  workspace => workspace.permissions
);

export const getUserEmployee = createSelector(
  getActiveWorkspace,
  workspace => (workspace && workspace.user) || {}
);

export const getUserCurrency = createSelector(
  getUserEmployee,
  user => (user || {}).currency
);

export const getUserPriceVisible = createSelector(getUserEmployee, user => {
  const { userPriceVisible } = user || {};

  return typeof userPriceVisible === 'undefined' ? true : userPriceVisible;
});

export const getUserIsSubscribed = createSelector(getUserEmployee, employee => {
  const {
    categories = [],
    regions = [],
    receiveFromCompanies,
    receiveFromIndividuals
  } = employee;

  return (
    !!categories.length ||
    !!regions.length ||
    receiveFromIndividuals ||
    receiveFromCompanies
  );
});

export const getUserPermissions = createSelector(
  getUserEmployee,
  user => (user || {}).permissions
);

export const getEmployeeId = createSelector(getAllWorkspaces, entities =>
  memoize(workspaceId => entities[workspaceId].user.id)
);

export const getHasUser = createSelector(getActiveWorkspace, workspace =>
  memoize(userId => workspace.user.id === userId)
);

export const getEmployeeCategories = createSelector(
  getUserEmployee,
  employee => employee.categories
);

export const getEmployeeRoles = createSelector(
  getUserEmployee,
  employee => employee.roles || []
);

export const getEmployeeDepartment = createSelector(
  getUserEmployee,
  employee => employee.department
);

export const getIsMainManager = createSelector(getUserEmployee, employee =>
  employee.department
    ? employee.department.type === MAIN &&
      employee.department.managerId === employee.id
    : false
);

export const getIsUserOnBench = createSelector(getUserEmployee, employee =>
  employee.department ? employee.department.type === BENCH : false
);

export const getIsAdministrator = createSelector(getEmployeeRoles, roles =>
  (roles || []).some(role => role.type === ADMIN)
);

export const getIsOwner = createSelector(getEmployeeRoles, roles =>
  (roles || []).some(role => role.type === OWNER)
);

export const getIsController = createSelector(getEmployeeRoles, roles =>
  (roles || []).some(role => role.type === CONTROLLER)
);

export const getIsOneOfMain = createSelector(getEmployeeRoles, roles =>
  (roles || []).some(
    role => role.type === OWNER || role.type === ADMIN || role.type === MANAGER
  )
);

export const getWorkspaceCategories = createSelector(
  getActiveWorkspace,
  workspace => workspace.categories
);

export const getJoining = createSelector(
  getWorkspaces,
  workspace => workspace.joining
);

export const getIsSubmittedJoin = createSelector(getJoining, joining =>
  joining.value === undefined ? true : joining.value
);

export const getEvents = createSelector(
  getWorkspaces,
  workspace => workspace.events
);

export const getActiveEvents = createSelector(
  [getWorkspaceId, getEvents],
  (workspaceId, events) => {
    if (!Object.keys(events).length || !workspaceId) {
      return undefined;
    }

    return (events[workspaceId] && events[workspaceId][EMPLOYEE]) || {};
  }
);

export const getPublicContractFile = createSelector(
  getActiveWorkspace,
  workspace => workspace.publicContractFile
);

export const getEmployeeSettings = createSelector(
  getActiveWorkspace,
  workspace => workspace.settings || []
);
